import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Content, Paragraph, TextContainer } from "@siteimprove/fancylib";
import {
	ContentSection,
	DocPageMeta,
	Example,
	Header,
	HeaderSection,
	ImportExample,
	Code,
} from "../../../../../src/docs";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ThemeProvider, useDesignToken, useTheme } from "./theme";

export const Meta: DocPageMeta = {
	category: "Context",
	title: "Theme Provider",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Fancy Themes"
			subTitle="This guide will show you how to use Fancy Themes."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ThemeProvider} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Themes serve as a powerful tool to transform the appearance and experience of components
					within an application. They are implemented through the use of the{" "}
					<Code>ThemeProvider</Code> component. Each theme distinguishes itself by configuring a
					unique set of values for our design tokens, altering the visual characteristics of
					components. To explore all the available design tokens, refer to the{" "}
					<GatsbyLink to="/design-tokens/">Design Tokens</GatsbyLink> page.
				</Paragraph>
				<Paragraph>
					For a component to be adaptable to various themes, it needs to rely on design tokens
					dynamically rather than static values. This implies that the component's CSS should be
					crafted to allow for theming by utilizing <Code>var(--design-token-name)</Code>{" "}
					declarations, which is known as "CSS custom properties" or "CSS variables". Consider the
					following example, showcasing how a component's CSS might be structured:
				</Paragraph>
				<CodeBlock language="css">{`
.box {
	background-color: var(--color--background--static--secondary);
	border: 1px solid var(--color--border--separator);
	color: var(--color--text--static--body);
}
`}</CodeBlock>
				<Paragraph>
					The <Code>ThemeProvider</Code> supplies the values for these design tokens, thereby
					influencing the appearance of components. In cases where no specific theme is provided,
					the system resorts to using the default theme.
				</Paragraph>
				<Example fn={BasicUsage} />
				<Paragraph>
					<strong>Note:</strong> The <Code>ThemeProvider</Code> component must be placed at the root
					of your application to ensure that all components have access to the active theme. The
					associated stylesheets of each theme are automatically injected into the document by the{" "}
					<Code>global.css</Code> file (see{" "}
					<GatsbyLink to="/lib/components/Context/Fancy Context/">Fancy Context</GatsbyLink> page).
					However, if our global CSS file introduces conflicts with your existing styles, you can
					import only the <Code>themes.css</Code> file, which contains the design-tokens styles for
					all themes.
					<CodeBlock language="css">{`
@import "@siteimprove/fancylib/themes.css";
`}</CodeBlock>
				</Paragraph>
				<Paragraph>
					Alternativelly, you can manually import the associated stylesheet for each theme, as shown
					below:
				</Paragraph>
				<CodeBlock language="css">{`
@import "@siteimprove/fancylib/design-tokens/themes/default.css";
@import "@siteimprove/fancylib/design-tokens/themes/dark.css";
`}</CodeBlock>
				<Header.H2>Accessing the active theme</Header.H2>
				<Paragraph>
					You can also use the <code>useTheme</code> hook to access the current theme in your
					components. This is useful if you want to conditionally render components based on the
					active theme.
				</Paragraph>
				<Example fn={UseThemeExample} />
				<Header.H2>Using design tokens programmatically</Header.H2>
				<Paragraph>
					You can use the <code>useDesignTokens</code> hook to access the current theme's design
					tokens. This is useful if you want to use the design tokens in your own components keeping
					your components in sync with the theme.
				</Paragraph>
				<Example fn={UseDesignTokensExample} />
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => {
	const boxStyle = {
		width: "200px",
		height: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "var(--color--background--static--secondary)",
		border: "1px solid var(--color--border--separator)",
		color: "var(--color--text--static--body)",
	};

	return (
		<Content flexDirection="row" gap="medium">
			<ThemeProvider theme="default">
				<div style={boxStyle}>Default Theme</div>
			</ThemeProvider>
			<ThemeProvider theme="dark">
				<div style={boxStyle}>Dark Theme</div>
			</ThemeProvider>
		</Content>
	);
};

const UseThemeExample = () => {
	const ThemedContent = () => {
		const activeTheme = useTheme();
		return <div>{activeTheme === "dark" ? "Dark Theme" : "Default Theme"}</div>;
	};

	return (
		<>
			<ThemeProvider theme="default">
				<ThemedContent />
			</ThemeProvider>
			<ThemeProvider theme="dark">
				<ThemedContent />
			</ThemeProvider>
		</>
	);
};

const UseDesignTokensExample = () => {
	const ThemedText = ({ children }: { children: React.ReactNode }) => {
		const { ColorTextStaticBody } = useDesignToken();
		return <span style={{ color: ColorTextStaticBody }}>{children}</span>;
	};

	return (
		<>
			<ThemeProvider theme="default">
				<ThemedText>Default Theme</ThemedText>
			</ThemeProvider>
			<ThemeProvider theme="dark">
				<ThemedText>Dark Theme</ThemedText>
			</ThemeProvider>
		</>
	);
};
